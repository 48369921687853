import { template as template_6ce0ea45596348fcae81c03eae88cac2 } from "@ember/template-compiler";
const FKLabel = template_6ce0ea45596348fcae81c03eae88cac2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
