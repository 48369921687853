import { template as template_256e5bb8e116400c9b0014659ee077ac } from "@ember/template-compiler";
const FKControlMenuContainer = template_256e5bb8e116400c9b0014659ee077ac(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
