import { template as template_c602a1d74599436787b69770428cc8a6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c602a1d74599436787b69770428cc8a6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
