import { template as template_904ff2d718124145bed4d66f5a08dab7 } from "@ember/template-compiler";
const WelcomeHeader = template_904ff2d718124145bed4d66f5a08dab7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
